<template>
  <main class="ambassador">
    <section class="ambassador__content">
      <section class="cards">
        <indication></indication>
        <section class="cards__content">
          <referred-friends
            :maxWidth="maxWidthReferred"
            @filter="filter('referred-friends')"
          ></referred-friends>
          <earnings-referrals
            :maxWidth="maxWidthEarnings"
            @filter="filter('earnings-referrals')"
          ></earnings-referrals>
        </section>
      </section>
    </section>
    <section class="ambassador__details">
      <section class="ambassador__details__content">
        <h3 class="title">{{ $t("views.ambassador.details.title") }}</h3>
        <component :is="selectedTable" />
      </section>
    </section>
  </main>
</template>

<script>
// Components
import Indication from "./Cards/Indication.vue";
import ReferredFriends from "./Cards/ReferredFriends.vue";
import EarningsReferrals from "./Cards/EarningsReferrals.vue";
import FriendsTable from "./Details/FriendsTable.vue";
import SalesTable from "./Details/SalesTable.vue";
export default {
  components: {
    Indication,
    ReferredFriends,
    EarningsReferrals,
    FriendsTable,
    SalesTable,
  },
  data: () => ({
    filterBy: null,
  }),
  computed: {
    maxWidthReferred() {
      if (!this.filterBy) return "100%";
      if (window.innerWidth < 768) return "100%";
      return this.filterBy === "referred-friends" ? "70%" : "30%";
    },
    maxWidthEarnings() {
      if (!this.filterBy) return "100%";
      if (window.innerWidth < 768) return "100%";
      return this.filterBy === "earnings-referrals" ? "70%" : "30%";
    },
    selectedTable() {
      if (!this.filterBy || this.filterBy === "earnings-referrals") {
        return "sales-table";
      }
      return "friends-table";
    },
  },
  methods: {
    filter(from = null) {
      this.filterBy = from;
    },
  },
};
</script>

<style lang="scss">
.ambassador {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    column-gap: 40px;
  }

  &__details {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    &__content {
      width: 100%;
      max-width: 1110px;
      height: 100%;
      display: flex;
      flex-direction: column;
      column-gap: 40px;
    }

    .title {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333;
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1110px;
    column-gap: 40px;
    row-gap: 30px;
    width: 100%;

    &__content {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      row-gap: 10px;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 270px;
    row-gap: 20px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .brasao {
    width: 270px;
    min-height: 50px;
    border-radius: 10px;
    color: #2366ca;
    background: #2366ca1a;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
</style>

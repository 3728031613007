var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"pagination":_vm.pagination},on:{"toPage":_vm.toPage,"perPage":_vm.perPage},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        title: _vm.getTextByType(item.type),
        placement: 'bottom',
      }),expression:"{\n        title: getTextByType(item.type),\n        placement: 'bottom',\n      }"}],staticClass:"type",class:_vm.checkType(item.type)})]}},{key:"id",fn:function(ref){
      var item = ref.item;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        title: _vm.getTextByType(item.type),
        placement: 'bottom',
      }),expression:"{\n        title: getTextByType(item.type),\n        placement: 'bottom',\n      }"}],staticClass:"d-none d-md-block",attrs:{"id":"id_venda"}},[_vm._v(" "+_vm._s(item.id)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <base-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :pagination="pagination"
    @toPage="toPage"
    @perPage="perPage"
  >
    <template #type="{ item }">
      <span
        class="type"
        v-b-tooltip="{
          title: getTextByType(item.type),
          placement: 'bottom',
        }"
        :class="checkType(item.type)"
      ></span>
    </template>
    <template #id="{ item }">
      <span
        class="d-none d-md-block"
        id="id_venda"
        v-b-tooltip="{
          title: getTextByType(item.type),
          placement: 'bottom',
        }"
      >
        {{ item.id }}
      </span>
    </template>
  </base-table>
</template>

<script>
// Components
import BaseTable from "@/components/shared/Table.vue";
// Services
import AmbassadorService from "@/services/resources/Ambassador";
const service = AmbassadorService.build();

// Mixins
import Money from "@/mixins/money";

// Utils
import moment from "moment";

export default {
  components: {
    BaseTable,
  },
  mixins: [Money],
  data() {
    return {
      headers: [
        {
          text: "",
          key: "type",
          cols: 1,
          md: 1,
          customClass: "d-none d-md-block",
        },
        {
          text: "#" + this.$t("views.ambassador.details.sales_table.id"),
          key: "id",
          cols: 1,
          customClass: "d-none d-md-block",
        },
        {
          text: this.$t("views.ambassador.details.sales_table.sales_date"),
          key: "sales_date",
          customClass: "d-none d-md-block",
        },
        {
          text: this.$t("views.ambassador.details.sales_table.referred_friend"),
          key: "indicated_friend",
          cols: 4,
          md: 2,
          customClass: "text-break",
        },
        {
          text: this.$t(
            "views.ambassador.details.sales_table.commission_amount"
          ),
          key: "comission_amount",
          cols: 4,
          md: 2,
        },
        {
          text: this.$t("views.ambassador.details.sales_table.product"),
          key: "product",
          cols: 4,
          md: 2,
        },
      ],
      items: [],
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  methods: {
    perPage(qtd) {
      this.pagination.perPage = qtd;
      this.toPage(1);
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.getSalesList();
    },
    checkType(type = null) {
      if (type === "SALE" || type === "AMBASSADOR_SALE") {
        return "is-green";
      } else if (
        type === "WITHDRAW" ||
        type === "WITHDRAW_FEE" ||
        type === "ANTECIPATION" ||
        type === "ANTECIPATION_FEE" ||
        type === "ANTECIPATION_BALANCE" ||
        type === "ANTECIPATION_REMAINING"
      ) {
        return "is-light-blue";
      } else if (
        type === "CHARGEBACK" ||
        type === "REFUNDED" ||
        type === "AMBASSADOR_CHARGEBACK" ||
        type === "AMBASSADOR_REFUNDED"
      ) {
        return "is-red";
      }

      return "";
    },
    getTextByType(type = null) {
      switch (type) {
        case "SALE":
          return "Venda";
        case "WITHDRAW":
          return "Saque";
        case "WITHDRAW_FEE":
          return "Taxa de saque";
        case "ANTECIPATION":
          return "Antecipação";
        case "ANTECIPATION_FEE":
          return "Taxa de Antecipação";
        case "ANTECIPATION_BALANCE":
          return "Saldo da Antecipação";
        case "ANTECIPATION_REMAINING":
          return "Antecipação Restante";
        case "CHARGEBACK":
          return "Chargeback";
        case "REFUNDED":
          return "Reembolso";
        case "AMBASSADOR_SALE":
          return "Comissão embaixador";
        case "AMBASSADOR_CHARGEBACK":
          return "Estorno comissão embaixador";
        case "AMBASSADOR_REFUNDED":
          return "Reembolso comissão embaixador";
        default:
          return "Venda";
      }
    },
    async getSalesList() {
      this.loading = true;
      const data = {
        url: "list-referred-amount",
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      await service
        .search(data)
        .then((resp) => {
          this.items = resp.data.map((item) => ({
            type: item.type,
            id: "#" + item.sale.id,
            sales_date: moment(item.sale.created_at).format("DD/MM/YYYY hh:mm"),
            referred_friend: item.user.name,
            comission_amount:
              item.base_currency.currency_symbol +
              " " +
              this.formatMoney(item.balance),
            product: item.product.name,
          }));
          this.pagination.currentPage = resp.current_page;
          this.pagination.totalPages = resp.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSalesList();
  },
};
</script>

<style lang="scss" scoped>
.type {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 20px;
}
</style>
